import React from 'react'
import Navbar from '../components/navbar'
import ProductMain from '../components/Product Page/productMain'
import Footer from '../components/footer'

export default function Services() {
  return (
    <div>
        <Navbar/>
        <ProductMain/>
        <Footer/>
        {/* <ServicesTwo/> */}
        
   
    </div>
  )
}
